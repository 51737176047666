import * as React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { graphql } from "gatsby";
import { CaseFragment } from "../../fragments"
import moment from "moment";

import Layout from "../../layouts/default-layout";

import PageHead from "../../components/pageHead";
import GetInTouch from "../../components/getInTouch";
import BodyBlockContent from "../../components/bodyBlockContent";

import {GatsbyImage} from 'gatsby-plugin-image'
import SanityImage from "gatsby-plugin-sanity-image"

import { extractImageFromData } from '../../lib/helpers';
import Share from "../../components/share";

const InsightDetailPage = ({ path, data }) => {
  const intl = useIntl()
  const content = data.sanityInsight;
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = content.meta_title ? content.meta_title[intl.locale] : title;
  const metaDescription = content.meta_description ? content.meta_description[intl.locale] : content.subline ? content.subline[intl.locale] : null;  

  return (
    <Layout textWhite={content.lightHeader} title={metaTitle} description={metaDescription} og_image={extractImageFromData(content, 'poster')} noIndex={content.meta_noindex} path={path}>
      <PageHead
        headline={title}
        backgroundImage={extractImageFromData(content, 'poster')}
        backgroundVideo={content.postervideo}
        textWhite={content.lightHeader}
        author={content.author}
        authorprofile={content.authorprofile}
        postdatetime={content.postdatetime}
      />
      
      {content._rawBody && (
        <div className="py-4">
          <BodyBlockContent data={content._rawBody[intl.locale]} />
        </div>
      )}
      
      {content.author && (
        <div className="tro-grid-responsive tro-default-padding-x">
          <div className="pt-4 mb-8 font-sans border-t-1 medium:mb-16 col-start-1 col-span-6 medium:col-start-3 medium:col-span-8">
            {content.authorprofile && (
              <a href={content.authorprofile} className="flex items-center hover:text-gray-800 hover:opacity-90 transition duration-100" target="_blank">
                {content.authorimage && content.authorimage.asset && (
                  <SanityImage className="w-20 h-20 mr-4 border-4 rounded-full border-brand-darkgrey" {...content.authorimage} width={400} height={400} alt={content.author} />
                )}
                <div>
                  <small className="block mb-1 font-light text-2xs medium:text-xs text-brand-dark">{ intl.formatMessage({ id: "author" }) }</small>
                  {content.author} &rarr;
                </div>
              </a>
            )}
            {!content.authorprofile && (
              <span className="flex items-center">
                {content.authorimage && content.authorimage.asset && (
                  <SanityImage className="w-12 h-12 mr-4 border-4 rounded-full border-brand-darkgrey" {...content.authorimage} width={400} height={400} alt={content.author} />
                )}
                <div>
                  <small className="block mb-1 font-light text-2xs medium:text-xs text-brand-dark">{ intl.formatMessage({ id: "author" }) }</small>
                  {content.author}
                </div>
              </span>
            )}
          </div>
        </div>
      )}
      <Share title={title} />
    </Layout>
  );
};

export default InsightDetailPage;

export const query = graphql`
  query insight ($id: String) {
    sanityInsight(id: { eq: $id }) {
      ... Insight
      meta_title {
        de
        en
      }
      meta_description {
        de
        en
      }
      meta_noindex
    }
    allSanityFileAsset {
      nodes {
        id
        url
      }
    }
  }
`
